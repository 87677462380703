import Vue from 'vue';

const TOASTS_DATA = {
  success: {
    summary: 'Success',
  },
  error: {
    summary: 'Error',
  },
  warn: {
    summary: 'Warning',
  },
  info: {
    summary: 'Info',
  }
}

/**
 * Type definition for the options object used in notifications.
 *
 * @typedef {object} NotificationOptions
 * @property {string} [summary] - Custom summary for the notification.
 * @property {number} [life] - The life of the notification in milliseconds.
 */

export const useNotification = () => {
  const toast = Vue.prototype.$toast;

  const show = (message, severity, summary, life = 3000) => {
    toast.add({
      severity,
      summary: summary ?? (TOASTS_DATA[severity]?.summary ?? 'Notification'),
      detail: message,
      life
    });
  }

  /**
   * Function to show a success notification.
   *
   * @param {string} message - The message to be displayed in the notification.
   * @param {NotificationOptions} [options={}] - Additional options for the notification.
   * @returns {void}
   */
  const showSuccess = (message, options = {}) => {
    show(message, 'success', options.summary, options.life);
  }

  /**
   * Function to show an error notification.
   *
   * @param {string} message - The message to be displayed in the notification.
   * @param {NotificationOptions} [options={}] - Additional options for the notification.
   * @returns {void}
   */
  const showError = (message, options = {}) => {
    show(message, 'error', options.summary, options.life);
  }

  /**
   * Function to show an info notification.
   *
   * @param {string} message - The message to be displayed in the notification.
   * @param {NotificationOptions} [options={}] - Additional options for the notification.
   * @returns {void}
   */
  const showInfo = (message, options = {}) => {
    show(message, 'info', options.summary, options.life);
  }

  /**
   * Function to show a warning notification.
   *
   * @param {string} message - The message to be displayed in the notification.
   * @param {NotificationOptions} [options={}] - Additional options for the notification.
   * @returns {void}
   */
  const showWarning = (message, options = {}) => {
    show(message, 'warn', options.summary, options.life);
  }

  const clearAllToasts = () => {
    toast.removeAllGroups()
  }

  return {
    toast: Vue.prototype.$toast,
    showSuccess,
    showError,
    showInfo,
    showWarning,
    clearAllToasts
  }
}
