import Vue from 'vue';
import App from './App.vue';
import './vee-validate.js'
import './assets/css/baseLayer.css'
import './assets/css/primevue/index.css'
import './assets/css/main.css'
import './primevue.js'
import { router } from './router/router.js'
import VueRouter from 'vue-router'
import { PiniaVuePlugin, createPinia } from 'pinia'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(PiniaVuePlugin)

const pinia = createPinia()

new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount('#app');
