export default (api) => ({
  getEmployees: (params) => api.get('/payoro/employees', { params }),
  getEmployeeInfo: (id) => api.get(`/payoro/employees/${id}`),
  createEmployee: (data) => api.post('/payoro/employees', data),
  updateEmployee: (id, data) => api.put(`/payoro/employees/${id}`, data),
  generateTotpSecret: () => api.post('/payoro/employees/totp'),
  validateTotpSecret: (data) => api.post('/payoro/employees/totp/validate', data),
  deleteTotpSecret: () => api.delete('/payoro/employees/totp'),
  deleteEmployee2FA: (id) => api.delete(`/payoro/employees/${id}/totp`),
  deleteEmployee: (id) => api.delete(`/payoro/employees/${id}`),
  generateRecoveryCodes: () => api.put('/payoro/employees/recovery'),
  getUserFactors: () => api.get('/payoro/employees/mfas'),
  getRoles: () => api.get('/payoro/employees/roles'),
})
