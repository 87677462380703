import FileDownload from 'js-file-download'
import { defineStore } from 'pinia'
import { api } from '../api/api.js';
import { calculateTotalPages } from '@/utils/calculateTotalPages';

export const useUserStore = defineStore({
  id: 'user-store',
  state: () => ({
    version: '',
    currentPage: '',
    pageHistory: ['/users'],
    transactions: {
      data: [],
      filters: {},
      statuses: [],
      pagination: {
        pageOffset: 0,
        pageSize: 10,
        sortBy: 'CreatedAt',
        sortOrder: 'asc',
        totalPages: 0,
        total: 0,
        currentPage: 1
      },
      error: false
    },
    userDuplicates: {
      data: [],
      error: false,
      pagination: {
        pageOffset: 0,
        pageSize: 10,
        sortBy: 'name',
        sortOrder: 'asc',
        totalPages: 0,
        total: 0,
        currentPage: 1
      }
    },
    transaction: {
      info: {}
    },
    currentUser: null,
    user: {
      info: {},
      transactions: {
        data: [],
        pagination: {
          pageOffset: 0,
          pageSize: 10,
          sortBy: 'CreatedAt',
          sortOrder: 'asc',
          totalPages: 0,
          total: 0,
          currentPage: 1
        }
      },
      files: {
        data: [],
        pagination: {
          pageOffset: 0,
          pageSize: 10,
          sortBy: 'CreatedAt',
          sortOrder: 'asc',
          totalPages: 0,
          total: 0,
          currentPage: 1
        }
      }
    }
  }),
  actions: {
    async loadVersion() {
      const { data } = await api.general.getEnvData()

      this.version = data?.version || ''
    },
    async getCurrentUser() {
      const { data } = await api.auth.getCurrentUser()

      this.currentUser = data
    },
    async getUserDuplicates() {
      try {
        this.userDuplicates.error = false
        const { data } = await api.users.getDuplicates({
          pageOffset: this.userDuplicates.pagination.pageOffset,
          pageSize: this.userDuplicates.pagination.pageSize,
          sortBy: this.userDuplicates.pagination.sortBy,
          sortOrder: this.userDuplicates.pagination.sortOrder
        })
        this.userDuplicates.data = data.duplicationList
        this.userDuplicates.pagination.total = data.total
        this.userDuplicates.pagination.totalPages = calculateTotalPages(data.total, this.userDuplicates.pagination.pageSize)
        return data
      } catch (error) {
        this.users.error = true
      }
    },
    async mergeUsers(usersData) {
      try {
        this.userDuplicates.error = false
        const { data } = await api.users.mergeUsers(usersData)
        this.getUserDuplicates()
        return data
      } catch (error) {
        this.users.error = true
      }
    },
    selectPage(tableName, page) {
      switch (tableName) {
        case 'User Duplicates':
          this.userDuplicates.pagination.pageOffset = page - 1
          this.userDuplicates.pagination.currentPage = page
          this.getUserDuplicates()
          break
        case 'User Transactions':
          this.user.transactions.pagination.pageOffset = page - 1
          this.user.transactions.pagination.currentPage = page
          this.getUserTransactions(this.user.info.id)
          break
      }
    },
    async getUserInfo(id) {
      try {
        const { data } = await api.users.getUserInfo(id)
        this.user.info = {
          ...data,
          merchantName: ''
        }

        return data
      } catch (error) {

      }
    },
    async getEmployeeInfo(id) {
      try {
        const { data } = await api.payoro.getEmployeeInfo(id)
        return data
      } catch (error) {
        throw error
      }
    },
    async updateUserData(userData, id) {
      try {
        const { data } = await api.users.updateUser(id, userData)
        return data
      } catch (error) {

      }
    },
    async getUserTransactions(id) {
      try {
        const { data } = await api.transactions.getUserTransactions(id, {
          pageOffset: this.user.transactions.pagination.pageOffset,
          pageSize: this.user.transactions.pagination.pageSize,
          sortBy: this.user.transactions.pagination.sortBy,
          sortOrder: this.user.transactions.pagination.sortOrder
        })
        this.user.transactions.pagination.total = data.total
        this.user.transactions.data = data.transactions
        this.user.transactions.pagination.totalPages = calculateTotalPages(data.total, this.user.transactions.pagination.pageSize)
      } catch (error) {

      }
    },
    async createPayoroEmployee(employeeData) {
      try {
        const { data } = await api.payoro.createEmployee(employeeData)
        return data
      } catch (error) {
        throw error
      }
    },
    async updateEmployeeData(employeeData, id) {
      try {
        const { data } = await api.payoro.updateEmployee(id, {
          ...employeeData,
          id
        })
        return data
      } catch (error) {
        throw error
      }
    },
    async getTransactionInfo(id) {
      try {
        const { data } = await api.transactions.getTransactionInfo(id)
        this.transaction.info = data
        this.transaction.info.merchantName = ''
      } catch (error) {

      }
      try {
        const { data: merchantInfo } = await api.merchants.getMerchantInfo(this.transaction.info.merchantId)

        this.transaction.info.merchantName = merchantInfo.merchant.name
      } catch (error) {

      }
    },
    async createMerchant(merchantData) {
      try {
        const { data } = await api.merchants.createMerchant(merchantData)
        return data
      } catch (error) {
        throw error
      }
    },
    handleHistoryBack() {
      this.pageHistory.pop()
      this.pageHistory.pop()
    },
    async exportCSV(name) {
      let resp
      switch (name) {
        case 'Transactions':
          resp = await api.transactions.exportCSV(this.transactions.filters)

          FileDownload(resp.data, `${name}.csv`)
          break
        default:
          break
      }
    }
  }
})
