import {
  DAILY_REPORT_TYPE,
  FINANCE_REPORT_TYPE
} from '@/constants/reportTypes';

export default (api) => ({
  [DAILY_REPORT_TYPE]: {
    getRecipients: () => api.get('/reports/daily-performance/recipients'),
    deleteRecipient: (id) => api.delete(`/reports/daily-performance/recipients/${id}`),
    addRecipient: (email) => api.post('/reports/daily-performance/recipients/add', {
      recipient: email
    }),
    triggerReport: () => api.post('/reports/daily-performance/trigger'),
  },

  [FINANCE_REPORT_TYPE]: {
    getRecipients: () => api.get('/reports/finance/recipients'),
    deleteRecipient: (id) => api.delete(`/reports/finance/recipients/${id}`),
    addRecipient: (email) => api.post('/reports/finance/recipients/add', {
      recipient: email
    }),
    triggerReport: (data) => api.post('/reports/finance/trigger', data),
  },
})
