import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/userStore.js'
import { checkPermission } from '@/utils/checkPermission.js'
import { router } from '@/router/router'

export const usePermissions = () => {
  const { currentUser } = storeToRefs(useUserStore())

  /**
   * Checks if the user has all the specified permissions based on his roles.
   *
   * @param {...string} permissions - The permissions to check.
   * @returns {boolean} `true` if the user has all the specified permissions; otherwise `false`.
   *
   * @example
   * const canAccess = hasPermissions(PERMISSIONS.FINANCE_REPORTS_VIEW, PERMISSIONS.DASHBOARD_USER_CREATE);
   * console.log(canAccess); // Outputs: true or false depending on the user's permissions
   */
  const hasPermissions = (...permissions) => {
    if (permissions.length === 0) {
      return true
    }

    return permissions.every(permission => checkPermission(currentUser.value, permission))
  }

  const canAccessPage = (route) => {
    const resolvedRoute = router.resolve(route).resolved

    const requiredPermissions = resolvedRoute.meta.permissions ?? []

    return hasPermissions(...requiredPermissions)
  }

  return {
    hasPermissions,
    canAccessPage
  }
}
