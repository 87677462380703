export default (api) => ({
  getLateTransactions: (params) => api.get('/transactions/late', { params }),
  getNonMatchingIBANTransactions: (params) => api.get('/transactions/iban/collide', { params }),
  getFilteredTransactions: (body, params) => api.post('/transactions/filter', body, { params }),
  getStatuses: () => api.get('/transactions/statuses'),
  getUserTransactions: (id, params) => api.get(`/transactions/users/${id}`, { params }),
  getTransactionInfo: (id) => api.get(`/transactions/${id}`),
  exportCSV: (data) => api.post('/transactions/filter/csv', data, {
    responseType: 'blob',
  }),
  updateTransactionIban: (id, iban) => api.put(`/transactions/${id}/iban`, { iban }),
  updateTransactionStatus: (id, status) => api.put(`/transactions/${id}/status`, { status }),
  alternatePayOut: (id) => api.post(`/transactions/${id}/alternate-route`),
  resendMerchantNotification: (id) => api.post(`/transactions/${id}/notify`)
})
