import { HOME_ROUTE_BY_ROLE } from '@/constants/permissions'

export const getHomeRoute = (roles) => {
  for (const role in HOME_ROUTE_BY_ROLE) {
    if (roles.includes(role)) {
      return HOME_ROUTE_BY_ROLE[role]
    }
  }

  return { name: 'home' }
}
