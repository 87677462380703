import { rolesMapping } from '@/constants/permissions.js'

/**
 * Checks if a user has a specific permission based on his roles.
 *
 * @param {object} user - The user object.
 * @param {Array<string>} [user.roles] - An array of roles assigned to the user.
 * @param {string} permission - The permission to check for.
 * @returns {boolean} `true` if the user has the specified permission; otherwise `false`.
 *
 * @example
 * const user = { roles: ['admin'] };
 * const canAccessFinanceReports = checkPermission(user, PERMISSIONS.FINANCE_REPORTS_VIEW);
 * console.log(canViewDashboard); // Outputs: true
 */
export const checkPermission = (user, permission) => {
  if (!user || !Array.isArray(user?.roles)) {
    return false
  }

  return user.roles.some((role) => {
    if (!rolesMapping[role]) {
      return false
    }

    return rolesMapping[role].includes(permission)
  })
}
