export default (api) => ({
  getAdjustments: (params) => api.get('/funds-adjustments/latest-per-merchant', { params }),
  clearConnectPay: (merchantId, amount) => api.post('/funds-adjustments/connect-pay', {
    merchantId,
    amount
  }),
  clearECFees: (merchantId, amount) => api.post('/funds-adjustments/customer-fees', {
    merchantId,
    amount
  }),
  clearMerchantFees: (merchantId, amount) => api.post('/funds-adjustments/merchant-fees', {
    merchantId,
    amount
  }),
  clearGatewayFees: (merchantId, amount) => api.post('/funds-adjustments/gateway-fees', {
    merchantId,
    amount
  }),
  clearEcbRateMargins: (merchantId, amount) => api.post('/funds-adjustments/ecb-rate-margins', {
    merchantId,
    amount
  }),
})
