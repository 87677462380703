import axios from 'axios'
import {
  getAccessToken,
  logout
} from '../utils/auth.js';

const apiBaseUrl = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_API_ROOT

const API_ROOT = axios.create({
  baseURL: apiBaseUrl
})

API_ROOT.interceptors.request.use(
  (config) => {
    const token = getAccessToken()
    config.headers.Authorization = `Bearer ${token}`

    return config
  },
  (error) => {
    return error
  }
)

API_ROOT.interceptors.response.use(null, async (error) => {
  const isLoginPage = window.location.pathname.replaceAll('/', '') === 'login'
  if (error.response?.status === 401 && !error.config.ignoreAuth && !isLoginPage) {
    await logout()
  }

  return Promise.reject(error)
})

export {
  API_ROOT,
}
