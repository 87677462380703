export default (api) => ({
  getAccessToken: (namespace, data) => api.post(`proxy/${namespace}/oauth2/token`, data, {
    ignoreAuth: true
  }),
  createTransaction: (namespace, data, accessToken) => api.post(`proxy/${namespace}/withdrawal`, data, {
    headers: {
      'Service-Authorization': `Bearer ${accessToken}`
    },
    ignoreAuth: true
  })
})
