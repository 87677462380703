<template>
  <div class="min-h-screen">
    <Toast />
    <RouterView v-if="isUserLoaded" />
  </div>
</template>

<script setup>
import { useUserStore } from '@/store/userStore'
import { setupHooks } from '@/router/router'
import {
  useRoute,
  useRouter
} from 'vue-router/composables'
import { ref } from 'vue'
import { getHomeRoute } from '@/utils/getHomeRoute'
import { usePermissions } from '@/composables/permissions'
import { useNotification } from '@/composables/notification'
import { logout } from '@/utils/auth'

const userStore = useUserStore()
const { hasPermissions } = usePermissions()
const { showWarning } = useNotification()
const router = useRouter()
const route = useRoute()

const isUserLoaded = ref(false)

const loadCurrentUser = async () => {
  try {
    await userStore.getCurrentUser()

    const homeRoute = getHomeRoute(userStore.currentUser.roles)
    const canStayOnCurrentPage = hasPermissions(...(route.meta.permissions ?? []))

    if (route.name === 'auth:login' || !canStayOnCurrentPage) {
      await router.replace(homeRoute)

      if (!canStayOnCurrentPage) {
        showWarning('You do not have permissions to access this page', {
          summary: 'Access Denied',
        })
      }
    }
  } finally {
    setupHooks()
    isUserLoaded.value = true
  }
}

router.onReady(loadCurrentUser)
</script>
