export default (api) => ({
  getMerchants: () => api.get('/balance-alerts/merchants'),
  updateMerchants: (merchants) => api.put('/balance-alerts/merchants/update-data', merchants),
  getRecipients: () => api.get('/balance-alerts/recipients'),
  addRecipient: (email) => api.put('/balance-alerts/recipients/add', {
    email
  }),
  removeRecipient: (email) => api.put('/balance-alerts/recipients/remove', {
    email
  }),
  getLogs: (params) => api.get('/balance-alerts/logs', { params }),
})
