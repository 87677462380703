export default (api) => ({
  getFilteredUsers: (body, params) => api.post('/users/filter', body, { params }),
  getDuplicates: (params) => api.get('/users/duplicates', { params }),
  getMissingDetails: (params) => api.get('/users/missing/details', { params }),
  mergeUsers: (data) => api.post('/users/merge', data),
  getUserInfo: (id) => api.get(`/users/${id}`),
  updateUser: (id, data) => api.put(`/users/${id}`, data),
  exportCSV: (data) => api.post('/users/filter/csv', data, {
    responseType: 'blob'
  }),
  updateTransitIban: (id, transitIbanId, iban) => api.put(`/users/${id}/transitIban/${transitIbanId}`, { iban }),
  getUserTransitIban: (id) => api.get(`/users/${id}/transitIban`),
  getUserIban: (iban) => api.get(`/users/iban/${iban}`),
})
